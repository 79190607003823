import React from "react";
import { SelectDefault } from "../../common/select";

const SelectImage = ({ handleSelectedImages, value, loadMoreImage }) => {
  return (
    <SelectDefault
      // placeholder="Select Asstes"
      selectLabel="Select Assets"
      value={value}
      handleValueUpdate={handleSelectedImages}
      isSearchable={true}
      isDisabled={false}
      isMulti={true}
      noOptionsMessage="Select ContentType First"
      loadMoreOptions={loadMoreImage}
      width={"700px"}
    />
  );
};

export default SelectImage;
