import { useCallback, useEffect, useState } from "react";
import { DeleteSvg } from "../../asstes/delete";
import { fetchData } from "../../services";
import { fetchSdkData } from "../../services/sdk";
import SelectStack from "../stack";
import SelectAssetType from "./fileType";
import SelectFolderType from "./folders";
import SelectImage from "./Image";
import { asstesContentType } from "./type";

const AsstesSelector = ({
  stack,
  createAssets,
  folderArray,
  asstesData,
  index,
  removeAsstesComponent,
}) => {
  const [selectedStack, setSelectedStack] = useState(null);
  const [stackType, setStackType] = useState([]);
  const [showFolder, setShowFolder] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedAsstesType, setSelectedAsstesType] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);

  const getAssetsFolder = useCallback(
    async ({ search, skip, limit }) => {
      try {
        const res = await fetchData({
          url: `https://api.contentstack.io/v3/assets?include_folders=true&include_count=true&limit=${limit}&skip=${skip}&query={"is_dir": true}&folder&query={ "name": { "$regex": "^${search}","$options": "i" } }`,
          api_key: stackType[0].api_key,
          management_token: stackType[0].management_token,
        });
        if (folderArray?.length > 0) {
          return {
            assets: res.assets.filter(
              (item) =>
                !folderArray.map((i) => i?.folder?.name).includes(item.name)
            ),
            count: res.count,
          };
        } else {
          return res;
        }
      } catch (err) {
        console.log(err);
        return {
          assets: [],
          count: 0,
        };
      }
    },
    [stackType, folderArray]
  );

  const getAssets = useCallback(
    async ({ search, skip, limit, isContentType = false }) => {
      try {
        if (selectedFolder?.value) {
          if (isContentType) {
            const res = await fetchData({
              url: `https://api.contentstack.io/v3/assets?include_folders=true&include_count=true&limit=${limit}&skip=${skip}&folder=${selectedFolder?.value}&query={ "content_type":{ "$regex": "^${search}","$options": "i" }}`,
              api_key: stackType[0].api_key,
              management_token: stackType[0].management_token,
            });
            return {
              items: res.assets,
              count: res.count,
            };
          } else {
            const res = await fetchData({
              url: `https://api.contentstack.io/v3/assets?include_folders=true&include_count=true&limit=${limit}&skip=${skip}&folder=${selectedFolder?.value}&query={ "filename": { "$regex": "^${search}","$options": "i" } }`,
              api_key: stackType[0].api_key,
              management_token: stackType[0].management_token,
            });
            return {
              items: res.assets,
              count: res.count,
            };
          }
        } else {
          const res = await fetchSdkData({
            api_key: stackType[0].api_key,
            management_token: stackType[0].management_token,
            query: {
              limit: limit,
              search: search,
              skip: skip,
              include_count: true,
              query: isContentType
                ? { content_type: { $regex: `^${search}`, $options: "i" } }
                : {
                    content_type: {
                      $regex: `^${selectedAsstesType.value ?? ""}`,
                      $options: "i",
                    },
                    filename: { $regex: `^${search}`, $options: "i" },
                  },
            },
            type: "/assets",
          });
          return res;
        }
      } catch (err) {
        console.log(err);
        return {
          assets: [],
          count: 0,
        };
      }
    },
    [selectedFolder, stackType, selectedAsstesType]
  );

  useEffect(() => {
    if (Object.keys(asstesData).length > 0) {
      setSelectedStack({
        label: asstesData.stack_name,
        value: asstesData.stack_name,
      });
      setStackType([
        {
          api_key: asstesData.api_key,
          management_token: asstesData.management_token,
          environment: asstesData.environment,
          access_token: asstesData.access_token,
        },
      ]);
      if (asstesData.folder !== undefined) {
        setShowFolder(true);
        setSelectedFolder({
          label: asstesData.folder.name,
          value: asstesData.folder.uid,
        });
      } else {
        setShowFolder(false);
        setSelectedFolder(null);
      }
      setSelectedAsstesType({
        label: asstesData.content_type,
        value: asstesData.content_type,
      });
      setSelectedImages(
        asstesData.assets.map((item) => {
          return {
            label: (
              <span className="flex-v-center col-gap-10">
                <img
                  src={item.url}
                  alt={item.title}
                  width="18px"
                  height="18px"
                />
                {item.title}
              </span>
            ),
            value: item.uid,
            url: item.url,
          };
        })
      );
    }
  }, [asstesData]);

  const loadMoreFolderType = async ({ search, skip, limit }) => {
    let data = await getAssetsFolder({
      search: search,
      skip: skip,
      limit: limit,
    });
    let newData = data?.assets?.map((item) => {
      return { label: item.name, value: item.uid };
    });
    return {
      options: newData ?? [],
      hasMore: newData.length > skip + limit,
    };
  };

  const loadMoreAsstesType = async ({ search, skip, limit }) => {
    let data = await getAssets({
      search: search,
      skip: skip,
      limit: limit,
      isContentType: true,
    });
    console.log(data);
    let presentType = asstesContentType.filter((item) =>
      data?.items?.map((v) => v.content_type).includes(item)
    );
    let newData = presentType?.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    return {
      options: newData ?? [],
      hasMore: newData.length > skip + limit,
    };
  };

  const loadMoreImage = async ({ search, skip, limit }) => {
    let data = await getAssets({
      search: search,
      skip: skip,
      limit: limit,
    });
    let presentType = data?.items?.filter(
      (item) => item.content_type === selectedAsstesType.value
    );
    let newData = presentType?.map((item) => {
      return {
        label: (
          <span className="flex-v-center col-gap-10">
            <img
              src={item.url}
              alt={item.filename}
              width="18px"
              height="18px"
            />
            {item.filename}
          </span>
        ),
        value: item.uid,
        url: item.url,
      };
    });
    return {
      options: newData ?? [],
      hasMore:
        newData?.length > 8 ? data.count : newData?.length > skip + limit,
    };
  };

  const handleSelectedStackType = async (data) => {
    setSelectedFolder(null);
    setSelectedAsstesType(null);
    setSelectedImages(null);
    setSelectedStack(data);
    if (data) {
      setStackType(stack[data.value]);
      const { assets } = await fetchData({
        url: `https://api.contentstack.io/v3/assets?include_folders=true&include_count=true&query={"is_dir": true}&folder`,
        api_key: stack[data.value][0].api_key,
        management_token: stack[data.value][0].management_token,
      });
      assets.length > 0 ? setShowFolder(true) : setShowFolder(false);
    }
  };

  const handleSelectedFoldersType = (data) => {
    setSelectedAsstesType(null);
    setSelectedImages(null);
    setSelectedFolder(data);
  };
  const handleSelectedAssetsType = (data) => {
    setSelectedImages(null);
    setSelectedAsstesType(data);
  };

  const handleSelectedImages = (data) => {
    setSelectedImages(data);
    if (data) {
      let finalStack = {
        stack_name: selectedStack.value,
        access_token: stack[selectedStack.value][0].access_token,
        api_key: stack[selectedStack.value][0].api_key,
        environment: stack[selectedStack.value][0].environment,
        management_token: stack[selectedStack.value][0].management_token,
        folder: {
          uid: selectedFolder?.value,
          name: selectedFolder?.label,
        },
        content_type: selectedAsstesType.value,
        assets: data.map((item) => {
          return {
            title: item.label.props.children[1],
            url: item.url,
            uid: item.value,
          };
        }),
      };
      if (selectedFolder?.value === undefined) {
        delete finalStack.folder;
      }
      createAssets(finalStack, index);
    }
  };

  return (
    <div className="container">
      <div className="wrappper">
        <div className="component-container">
          <SelectStack
            stackType={stack}
            handleSelectedStackType={handleSelectedStackType}
            value={selectedStack}
          />
        </div>
        {showFolder ? (
          <>
            <div className="component-container">
              <SelectFolderType
                handleSelectedFoldersType={handleSelectedFoldersType}
                value={selectedFolder}
                loadMoreFolderType={loadMoreFolderType}
              />
            </div>
            <div className="component-container">
              {selectedStack && (
                <SelectAssetType
                  handleSelectedAssetsType={handleSelectedAssetsType}
                  value={selectedAsstesType}
                  loadMoreAsstesType={loadMoreAsstesType}
                />
              )}
            </div>
          </>
        ) : (
          <div className="component-container">
            {selectedStack && (
              <SelectAssetType
                handleSelectedAssetsType={handleSelectedAssetsType}
                value={selectedAsstesType}
                loadMoreAsstesType={loadMoreAsstesType}
              />
            )}
          </div>
        )}
      </div>
      <div className="localize-container">
        <div className="entry-container">
          {selectedAsstesType && (
            <SelectImage
              handleSelectedImages={handleSelectedImages}
              value={selectedImages}
              loadMoreImage={loadMoreImage}
            />
          )}
        </div>
        <div>
          <DeleteSvg
            id={
              showFolder
                ? selectedFolder?.value
                : selectedImages && selectedImages[0]?.value
            }
            isFolder={showFolder}
            removeComponent={removeAsstesComponent}
          />
        </div>
      </div>
    </div>
  );
};

export default AsstesSelector;
