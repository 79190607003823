import React from "react";
import { SelectDefault } from "../../common/select";

const SelectAssetType = ({
  handleSelectedAssetsType,
  value,
  loadMoreAsstesType,
}) => {
  return (
    <SelectDefault
      selectLabel="Select AssetType"
      value={value}
      handleValueUpdate={handleSelectedAssetsType}
      isSearchable={true}
      isDisabled={false}
      isClearable={true}
      isMulti={false}
      noOptionsMessage="Select ContentType First"
      loadMoreOptions={loadMoreAsstesType}
    />
  );
};

export default SelectAssetType;
